<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color':''"
			@input="handleInput"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true">
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
			<span slot="noResult">
                {{ $t('no_result') }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError">
        </span>
	</div>
</template>

<script>
	// Helpers
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	// Services
	import UniversityService from "@/services/UniversityService";

	// Other
	import qs from "qs";

	export default {
		props: {
            isTurkey:{
                type:String,
            },
			isCountryRequired: {
				type: Boolean,
				default: false
			},
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
                country_Id:null,
				selected: null,
				options: [],
			}
		},
		watch: {
            isTurkey:{
                handler: function (val, oldVal) {
                    if (val != oldVal) {
                        this.getOptions(val)
                    }
                }
            },
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options)
			}
		},
		created() {
			this.getOptions();
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions(isTurkey) {
                if (isTurkey=="Yurt İçi"){
                    this.clearOptions()
                    this.countryId=null,
                    this.countryId = 174
                    let filter = {};
                    if(this.countryId){
                        filter.country_id = this.countryId;
                    }
                    const config = {
                        params: {
                            filter: filter,
                            sort: ('name'),
                            limit: -1
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false})
                    };
                    UniversityService.getAll(config)
                        .then(response => {
                            const data = response.data.data;
                            data.map(item => {
                                this.options.push({
                                    value: item.id,
                                    text: item.name,
                                });
                            })
                        })
                        .then(() => {
                            this.selected = this.setSelected(this.value, this.options)
                            if (this.selected == null || this.selected.length == 0) {
                                this.$emit("input", null)
                            }
                        })
                }
                else if (isTurkey=="Yurt Dışı"){
                        this.countryId=null
                    this.clearOptions()

                    let filter = {};
                    if(this.countryId){
                        filter.country_id = this.countryId;
                    }
                    const config = {
                        params: {
                            filter: filter,
                            sort: ('name'),
                            limit: -1
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false})
                    };
                    UniversityService.getAll(config)
                        .then(response => {
                            const data = response.data.data;
                            data.map((item) => {
                                if (item.country_id == 174){

                                }else {
                                    this.options.push({
                                        value: item.id,
                                        text: item.name +"  "+ item.country_name,
                                    });
                                }

                            })
                        })
                        .then(() => {
                            this.selected = this.setSelected(this.value, this.options)
                            if (this.selected == null || this.selected.length == 0) {
                                this.$emit("input", null)
                            }
                        })
                }


			},
			clearOptions() {
				this.options = [];
				this.selected = null;
			}
		}

	};
</script>
